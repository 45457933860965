import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Select, DatePicker, Button, message } from 'antd';
import SearchStyle from '../styles/search.module.css';
import { DataContext } from '../config/contexts';
import { getShippingMethod } from '../config/webAPIs';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Search = () => {
  const [ dateValue, setDateValue ] = useState();
  const [ shippingMethod, setShippingMethod ] = useState([]);

  useEffect(() => {
    getShippingMethod().then(res => {
      if(res.status === 200){
        setShippingMethod(res.data.data.shipping_method);
      }
    }).catch(error => {
      console.log(error);
    })
  }, []);

  const [form] = Form.useForm();

  const dataContext = useContext(DataContext);

  const shippingMethodChange = (value) => {
    form.setFieldsValue({
      shipping_method: value
    });
  };

  const orderStatusChange = (value) => {
    form.setFieldsValue({
      order_status: value
    });
  };

  const dateFormat = 'YYYY-MM-DD';

  const onFinish = (values) => {
    // console.log(values);
    values.date_range = dateValue;
    dataContext.getData(values);
    dataContext.setloading(true);
    dataContext.SetSelectedRowKeys([]);
  };

  return (
    <Form 
      form={ form } 
      name="control-hooks" 
      onFinish={ onFinish } 
      className={ SearchStyle.search }
      initialValues={{
        shipping_method: "",
        order_status: ""
      }}
    >
      <Form.Item
        className="form-item"
        name="company_code"
        label="Customer Code"
        rules={[
          { required: false },
          {
            pattern: new RegExp(/^[\w-]*$/),
            message: () => { message.error('Please input correct value') }
          }
        ]}
      >
        <Input className={ SearchStyle.customerCode_input } size="large" />
      </Form.Item>

      <Form.Item
        className="form-item"
        name="shipping_method"
        label="Shipping Method"
        rules={[{ required: false }]}
      >
        <Select
          onChange={ shippingMethodChange }
          size="large"
          allowClear
        >
          <Option value="">All</Option>
          { shippingMethod.map((item, i) => <Option value={ item } key={ i }>{ item }</Option>) }
        </Select>
      </Form.Item>

      <Form.Item
        className="form-item"
        name="date_range"
        label="Date Range"
        rules={[{ required: true, message: () => { message.error('Date Range is required') } }]}
      >
        <RangePicker 
          value={ dateValue }
          format={ dateFormat } 
          placeholder={['Start Date', 'End Date']}
          onChange={(value, dateString) => setDateValue(dateString)}
          size="large"
        />
      </Form.Item>

      <Form.Item
        className="form-item"
        name="order_status"
        label="Order Status"
        rules={[{ required: false }]}
      >
        <Select
          className="orderStatus-select"
          onChange={ orderStatusChange }
          size="large"
          allowClear
        >
          <Option value="">All</Option>
          <Option value="C">C: 待發貨審核</Option>
          <Option value="W">W: 待發貨</Option>
          <Option value="D">D: 已發貨</Option>
          <Option value="H">H: 暫存</Option>
          <Option value="N">N: 異常訂單</Option>
          <Option value="P">P: 問題件</Option>
          <Option value="X">X: 廢棄</Option>
        </Select>
      </Form.Item>

      <Form.Item
        className="form-item form-item-orderCode"
        name="OrderCode_TrackingNumber"
        label="order_code"
        rules={[
          { required: false },
          {
            pattern: new RegExp(/^[\w-]*$/),
            message: () => { message.error('Please input correct value') }
          }
        ]}
      >
        <Input 
          className={ SearchStyle.orderCode_input } 
          placeholder="( Order Code or Tracking Number )"
          size="large"
        />
      </Form.Item>

      <Form.Item className="form-item form-item-submitBtn" label="submit">
        <Button 
          type="primary" 
          htmlType="submit" 
          className={ SearchStyle.searchBtn } 
          size="large" 
        >
          SEARCH
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Search;