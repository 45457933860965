const TOKEN_NAME = 'token';
const USER_NAME = 'user';
const USER_ID = 'user_id';

// 存取 token 到 localStorage
export const setAuthToken = (token) => {
  localStorage.setItem(TOKEN_NAME, token);
};

// 從 localStorage 取得 token
export const getAuthToken = () => (
  localStorage.getItem(TOKEN_NAME)
);

// 存取 user 到 localStorage
export const setUserHandler = (username) => {
  localStorage.setItem(USER_NAME, username);
};

// 從 localStorage 取得 user
export const getUser = () => (
  localStorage.getItem(USER_NAME)
);

// 存取 userId 到 localStorage
export const setUserId = (userId) => {
  localStorage.setItem(USER_ID, userId);
};

// 從 localStorage 取得 userId
export const getUserId = () => (
  localStorage.getItem(USER_ID)
);