import React, { useState } from 'react';
import 'antd/dist/antd.css';
import '../styles/common.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthContext } from '../config/contexts';
import { getAuthToken } from '../config/utils';
import { RouteConfig } from '../config/route';

const App = () => {
  const [ user, setUser ] = useState(null);
  const token = getAuthToken();

  return (
    <>
      <AuthContext.Provider value={{ user, token, setUser }}>
        <Router>
          {
            RouteConfig.map( (route, index) => (
              <Route 
                key={ index }
                exact={ route.exact }
                component={ route.component }
                path={ route.path }
              />
            ))
          }
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;