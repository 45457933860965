import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import LoginStyles from '../styles/login.module.css';
import { Card, Form, Input, Button, message } from 'antd';
import logoImg from '../img/Continental_Logo.png';
import { login } from '../config/webAPIs';
import { setAuthToken, setUserHandler, setUserId } from '../config/utils';
import { AuthContext } from '../config/contexts';

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  
  // 送出登入表單
  const onFinish = (values) => {
    console.log(values);
    console.log('Success:', values);

    login(values).then(res => {
      if(res.status === 200){
        console.log(res);
        setAuthToken(res.data.data.token);
        setUserHandler(res.data.data.user.full_name);
        setUserId(res.data.data.user.id);
        authContext.setUser(res.data.data.user.full_name);
        // authContext.setUserId(res.data.data.user.id);
        history.push('/shipments');
      }
    })
    .catch(error => {
      // console.log(error);
      errorMsg();
    })
  };

  // 按 enter 也能送出表單
  const onkeydown = (e) => {
    if (e.keyCode === 13) {
      onFinish();
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // 欄位驗證錯誤訊息
  const errorMsg = () => {
    message.error('Please input correct username or password！');
  };

  return(
    <div className={ LoginStyles.login }>
      <div className={ LoginStyles.topbar }>
        <img src={ logoImg } alt="logo-img" />
      </div>
      <Card title="Log in" bordered={false} style={{ width: 400, margin: '0 auto' }}>
        <Form
          initialValues={{ remember: true }}
          onFinish={ onFinish }
          onFinishFailed={ onFinishFailed }
        >
          <Form.Item
            name="account"
            rules={[
              { required: true, message: () => { message.error('Please input your username!'); } },
              {
                pattern: new RegExp(/^[\w-_.]*$/),
                message: () => { message.error('Should be combination of numbers & alphabets'); }
              }
            ]}
          >
            <Input placeholder="Username" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[

              { required: true, message: () => { message.error('Please input your password!'); } },
              {
                pattern: new RegExp(/^[\w-_.]*$/),
                message: () => { message.error('Should be combination of numbers & alphabets'); }
              }
            ]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              className={ LoginStyles.submitBtn }
              onKeyDown={(e) => onkeydown(e) }
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default Login