import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from './Navbar';
import { Button } from 'antd';
import TopbarStyle from '../styles/topbar.module.css';
import { AuthContext } from '../config/contexts';
import { setAuthToken, setUserHandler, getUser, setUserId } from '../config/utils';

const Topbar = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const logout = () => {
    setAuthToken('');
    setUserHandler(null);
    setUserId(null);
    authContext.setUser(null);
    history.push('/');
  }

  useEffect(() => {
    if(authContext.token){
      const username = getUser();
      authContext.setUser(username);
    }
  }, [authContext])

  return(
    <div className={ TopbarStyle.topbar }>
      <Navbar />
      <div>
        <span className={ TopbarStyle.username }>Hi, { authContext.user }</span>
        <Button onClick={ logout } className={ TopbarStyle.logoutBtn }>LOG OUT</Button>
      </div>
    </div>
  )
}

export default Topbar;