import Login from '../pages/Login';
import Shipments from '../pages/Shipments';

export const RouteConfig = [
  { 
    path: "/",
    title: "Login", 
    exact: true, 
    component: Login
  },
  {
    path: "/shipments",
    title: "Shipments",
    exact: false,
    component: Shipments
  }
]