import axios from 'axios';
import { getAuthToken } from './utils';

const token = getAuthToken();

// 共用 headers
const headers = {
  'Content-Type': 'application/json',
  'token': token
}

// Login API
export const login = ( values ) => (
  axios({
    url: `/api/user/login`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: { 'data': values }
  })
)

// Get shipping Method API
export const getShippingMethod = () => (
  axios({
    url: `/api/shipments/shippingmethod`,
    method: 'GET',
    headers: { headers }
  })
)

// Search API
export const search = ( values, page ) => (
  axios({
    url: `/api/shipments?company_code=${values.company_code}&shipping_method=${values.shipping_method}&start_date=${values.date_range[0]}&end_date=${values.date_range[1]}&order_status=${values.order_status}&OrderCode_TrackingNumber=${values.OrderCode_TrackingNumber}&page=${page}`,
    method: 'GET',
    headers: { headers },
    data: { 'data': values }
  })
)

// Export Tracking Number API
export const exportTrackingNum = (values) => (
  axios({
    url: `/api/shipments/export?company_code=${values.company_code}&shipping_method=${values.shipping_method}&start_date=${values.date_range[0]}&end_date=${values.date_range[1]}&order_status=${values.order_status}&OrderCode_TrackingNumber=${values.OrderCode_TrackingNumber}`,
    method: 'POST',
    data: values,
    headers: { headers }
  })
)

// Print Label API
export const printLabel = (values) => (
  axios({
    url: `/api/shipments/printlabel`,
    method: 'POST',
    params: {
      printlabel: values
    },
    headers: { headers }
  })
)

// Sync Now API
export const syncNow = (values, userId) => (
  axios({
    url: `/api/shipments/syncNow?order_code=${values}&updated_by=${userId}`,
    method: 'POST',
    data: values,
    headers: { headers }
  })
)
