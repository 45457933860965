import React, { useContext } from 'react';
import { Table, Button, Modal } from 'antd';
import DataTableStyle from '../styles/dataTable.module.css';
import { DataContext } from '../config/contexts';
import { SyncOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';

const DataTable = () => {
  const dataContext = useContext(DataContext);

  const columns = [
    {
      title: 'Order Date',
      dataIndex: 'order_date',
    },
    {
      title: 'Order Code',
      dataIndex: 'order_code',
    },
    {
      title: 'Order Status',
      dataIndex: 'order_status',
    },
    {
      title: 'Shipping Method',
      dataIndex: 'shipping_method',
    },
    {
      title: '# of Packages',
      dataIndex: 'of_packages',
    },
    {
      title: 'Shipment ID',
      dataIndex: 'shipment_id',
    },
    {
      title: 'Tracking No',
      dataIndex: 'tracking_no',
    },
    {
      title: 'Label Status',
      dataIndex: 'label_status',
    },
    {
      title: 'Printed',
      dataIndex: 'printed',
    },
    {
      title: 'Sync Now',
      dataIndex: 'sync_now',
      render: () => <button className={ DataTableStyle.detailBtn } onClick={ dataContext.showModal }><SyncOutlined /></button>
    },
  ];

  const handleOk = () => {
    dataContext.syncNowHandler();
    dataContext.setModalVisible(false);
  };

  const handleCancel = () => {
    dataContext.setModalVisible(false);
  };

  const hasData = dataContext.data.length > 0;

  return(
    <div className={ DataTableStyle.wrap }>
      <Modal
        visible={ dataContext.modalVisible }
        onOk={ handleOk }
        onCancel={ handleCancel }
        okText="Yes"
      >
        <ExclamationCircleTwoTone />
        <h2 style={{ textAlign: 'center' }}>Are you sure?</h2>
        <h3 style={{ textAlign: 'center' }}>This order is going to be synchronized</h3>
        <h3 style={{ textAlign: 'center' }}>Please check back later</h3>
      </Modal>
      <div className={ DataTableStyle.buttons }>
        <Button 
          type="primary" 
          disabled={ !dataContext.hasSelected } 
          size="large" 
          onClick={ dataContext.printLabelHandler }
          loading={ dataContext.printBtnLoading }
        >
          { dataContext.printBtnLoading ? 'Generating...' : 'Print Label' }
        </Button>
        <Button 
          type="primary" 
          disabled={ !hasData } 
          size="large"
          onClick={ dataContext.exportData }
          loading={ dataContext.exportBtnLoading }
        >
          { dataContext.exportBtnLoading ? 'Generating...' : 'Export Tracking Number' }
        </Button>
      </div>
        <Table 
          { ...dataContext.pageConfig }
          className={ DataTableStyle.table }
          rowSelection={ dataContext.rowSelection } 
          columns={ columns } 
          dataSource={ dataContext.data } 
          onRow={record => {
            return {
              onClick: e => { dataContext.SetSyncOrderCode(record.order_code) }, 
            };
          }}
        />
    </div>
  )
}

export default DataTable;