import React, { useState, useEffect, useContext } from 'react';
import { Spin, Modal, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Search from '../components/Search';
import DataTable from '../components/DataTable';
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";
import { AuthContext } from '../config/contexts';
import { DataContext } from '../config/contexts';
import { search, exportTrackingNum, printLabel, syncNow } from '../config/webAPIs';
import { getUserId } from '../config/utils';

const Shipments = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [ data, setData ] = useState([]);
  const [ loading, setloading ] = useState(false);
  const [ pageConfig, setPageConfig ] = useState({});
  const [ exportValue, setExportValue ] = useState({});
  const [ selectedRowKeys, SetSelectedRowKeys ] = useState([]);
  const [ exportBtnLoading, setExportBtnLoading ] = useState(false);
  const [ printBtnLoading, setPrintBtnLoading ] = useState(false);
  const [ syncOrderCode, SetSyncOrderCode ] = useState('');
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ syncLoading, setSyncLoading ] = useState(false);
  const [ syncRefreshValue, setSyncRefreshValue ] = useState({});
  const [ syncRefreshPage, setSyncRefreshPage ] = useState(1);

  useEffect(() => {
    // 判斷有 token 才能進入 shipments 頁面，否則返回登入頁面
    if(!authContext.token){
      history.push('/');
    }
  }, [authContext.token, history]);

  // Search
  const getData = async (values, page) => {
    // console.log(values);

    if(values.company_code === undefined){
      values.company_code = '';
    }

    if(values.OrderCode_TrackingNumber === undefined){
      values.OrderCode_TrackingNumber = '';
    }

    await search( values, page ).then(res => {
      if(res.status === 200){
        // console.log(res.data);
        setloading(false);
        setData( 
          res.data.data.shipments.map(row => ({
            key: row.id,
            order_date: row.date_create,
            order_code: row.order_code,
            order_status: row.order_status,
            shipping_method: row.shipping_method,
            of_packages: row.package_quantity,
            shipment_id: row.id,
            tracking_no: row.tracking_number,
            label_status: labelStatusHandler(row.label_status, row.exit_message),
            printed: row.printed_at,
          }))
        );

        setPageConfig({
          pagination : {
            current: page,
            showSizeChanger: false,
            total: res.data.meta.total,
            pageSize: res.data.meta.per_page,
            onChange: page => {
              getData(values, page);
              // console.log('page: ', page);
            }
          }
        })

        // 將 values 傳送給 export 及 sync 功能使用
        setExportValue(values);
        setSyncRefreshValue(values);
        setSyncRefreshPage(page);
      }
    })
    .catch(error => {
      console.log(error);
    })    
  }

  // Export Tracking Number
  const exportData = async () => {
    const values = exportValue;
    // console.log(values);
    setExportBtnLoading(true);
    setloading(true);

    await exportTrackingNum(values).then(res => {
      if(res.status === 200){
        // console.log(res);
        setExportBtnLoading(false);
        setloading(false);

        const b64Data = res.data.data.file_content;
        const pre = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        const url = pre + b64Data;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', res.data.data.file_name);
        document.body.appendChild(link);
        link.click();
      }
    }).catch(error => {
      console.log(error);
    })
  }

  // Print Label
  const printLabelHandler = async () => {
    const values = selectedRowKeys;
    // console.log(values);
    setPrintBtnLoading(true);
    setloading(true);

    await printLabel(values).then(res => {
      if(res.status === 200){
        setPrintBtnLoading(false);
        setloading(false);
        const b64Data = res.data.data.file_content;

        const blob = b64ToBlob(b64Data, "application/zip");
        fileSaver.saveAs(blob, res.data.data.file_name);
      }
    }).catch(error => {
      console.log(error);
    })
  }

  // Sync Now
  const syncNowHandler = async () => {
    const values = syncOrderCode;
    const userId = getUserId();
    console.log(values);
    setSyncLoading(true);

    await syncNow(values, userId).then(res => {
      if(res.status === 200){
        console.log(res);
        setSyncLoading(false);
        showResultSuccessModal();
      }
    }).catch(error => {
      // console.log(error);
      console.log(error.response);
      console.log(error.request);
      console.log(error.message);
      setSyncLoading(false);
      if(error.response.status === 400){
        showResultErrorModal();
      }
    })
  }

  // 判斷 Label Status 欄位顯示 Completed 或 Failed，若為 Failed，顯示錯誤訊息
  const labelStatusHandler = (label_status, exit_message) => {
    if(label_status === '200'){
      return <span>Completed</span>
    } else {
      return (
        <Tooltip placement="bottomLeft" title={ exit_message } color="#ff5252">
          <span style={{color: '#ff5252'}}>Failed</span>
        </Tooltip>
      )
    }
  }

  const onSelectChange = selectedRowKeys => {
    SetSelectedRowKeys(selectedRowKeys);
    // console.log(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      // console.log(record);
      return {
        disabled: record.label_status.props.children !== 'Completed'
      };
    }
  };

  // Sync Now 確認跳窗顯示
  const showModal = () => {
    setModalVisible(true);
  };

  // Sync Now 成功通知
  const showResultSuccessModal = () => {
    Modal.success({
      content: (
        <div>
          <h2 style={{ textAlign: 'center' }}>Completed Successfully</h2>
          <h3 style={{ textAlign: 'center' }}>Order was synchronized successfully!</h3>
        </div>
      ),
      onOk() {
        // console.log(syncRefreshValue);

        // 重新再 search 一次
        getData(syncRefreshValue, syncRefreshPage);
      },
    });
  };

  // Sync Now 失敗通知
  const showResultErrorModal = () => {
    Modal.error({
      content: (
        <div>
          <h2 style={{ textAlign: 'center' }}>Error</h2>
          <h3 style={{ textAlign: 'center' }}>Order synchronization failed!</h3>
        </div>
      ),
      onOk() {
        console.log('refresh');
      },
    });
  };

  const hasSelected = selectedRowKeys.length > 0;
  
  return(
    <DataContext.Provider value={{ data, loading, exportBtnLoading, printBtnLoading, pageConfig, rowSelection, hasSelected,  modalVisible, getData, setloading, SetSelectedRowKeys, exportData, printLabelHandler, SetSyncOrderCode, syncNowHandler, showModal, setModalVisible }}>
      <Spin tip="Loading... Please wait" spinning={ loading }>
        <Spin tip="Loading... Please wait" spinning={ syncLoading }>
          <div className="shipment">
            <Topbar />
            <div>
              <Search />
              <DataTable />
            </div>
          </div>
        </Spin>
      </Spin>
    </DataContext.Provider>
  )
}

export default Shipments