import React, { useState } from 'react';
import { Menu } from 'antd';

const Navbar = () => {
  const [ current, setCurrent ] = useState('shipments');
  const handleClick = (e) => {
    setCurrent(e.key);
  }

  return(
    <>
      <Menu onClick={ handleClick } selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="shipments">
          SHIPMENTS
        </Menu.Item>
        <Menu.Item key="create_label" disabled>
          CREATE LABEL
        </Menu.Item>
        <Menu.Item key="create_labels_via_csv" disabled>
          CREATE LABELS VIA CSV
        </Menu.Item>
      </Menu>
    </>
  )
}

export default Navbar;